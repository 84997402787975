<template>
  <v-dialog :retain-focus="false" v-model="dialogImport" max-width="35%" id="production-form-validation-dialog-upload" persistent>
    <v-card>
      <v-card-title><span v-html="$t('production_form_import_header')" /></v-card-title>
      <v-card-text>
        <v-layout row justify-center>
          <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
            <v-container fill-height>
              <v-layout row justify-center align-center>
                <v-progress-circular indeterminate color="secondary"></v-progress-circular>
              </v-layout>
            </v-container>
          </v-overlay>
        </v-layout>
        <div v-if="longPolling">
          <div v-html="$t('production_form_import_longpolling_info')" />
          <v-progress-linear :value="longPollingStatusPercentage" height="25" stream loading="loading">
            <strong>{{ this.longPollingStatus.rowsProcessed }} / {{ longPollingStatus.totalRecords }}</strong>
          </v-progress-linear>
        </div>
        <div v-if="!showResult && !longPolling" class="file-import text-h6 font-weight-light">
          <input type="file" id="file" @change="handleFileImport($event)" />
          <v-alert v-if="showInlineMessage" class="wrong-file-type-alert" color="red" type="error" density="compact">
            {{ errorMessage }}
          </v-alert>
        </div>
        <div class="report-box" v-if="this.showResult">
          <h6 v-html="$t('production_form_import_report')"></h6>
          <v-table>
            <tbody>
              <tr>
                <td>
                  <span v-html="$t('production_form_import_total_records')" />
                </td>
                <td align="right">{{ longPollingStatus.totalRecords }}</td>
              </tr>
              <tr>
                <td>
                  <span v-html="$t('production_form_import_total_updated')" />
                </td>
                <td align="right">{{ longPollingStatus.rowsUpdated }}</td>
              </tr>
              <tr>
                <td>
                  <span v-html="$t('production_form_import_total_new_contracts')" />
                </td>
                <td align="right">{{ longPollingStatus.rowsNewContracts }}</td>
              </tr>
              <tr>
                <td>
                  <span v-html="$t('production_form_import_total_error')" />
                </td>
                <td align="right">{{ longPollingStatus.rowsError }}</td>
              </tr>
              <tr>
                <td>
                  <span v-html="$t('production_forms_import_rows_total')" />
                </td>
                <td align="right">{{ longPollingStatus.rowsTotalSum }}</td>
              </tr>
            </tbody>
          </v-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="this.longPollingStatus.totalRecords == 0"
          id="check_btn"
          class="upload-button"
          color="primary"
          :disabled="!this.selectedFile"
          @click="uplod_file"
          :loading="loading"
        >
          <span v-html="$t('production_form_import')" />
        </v-btn>
        <v-btn
          v-if="this.longPollingStatus.totalRecords > 0 && this.longPollingStatus.rowsProcessed == this.longPollingStatus.totalRecords"
          id="check_btn"
          class="m-2"
          color="primary"
          :href="protocol + longPollingStatus.documentLink"
          v-html="$t('production_form_download')"
        ></v-btn>
        <v-btn v-if="!longPolling" id="check_btn" class="m-2" @click="closeDialogImport()">
          <span v-html="$t('production_form_cancel')" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, protocol } from '@/main'

const defaultPollingStatus = {
  productionFormId: 0,
  documentId: 0,
  totalRecords: 0,
  rowsProcessed: 0,
  rowsUpdated: 0,
  rowsNewContracts: 0,
  rowsError: 0
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProductionFormImportDialog',
  props: ['productionFormId', 'dialogImport'],
  data() {
    return {
      protocol: protocol,
      selectedFile: '',
      errorMessage: '',
      showInlineMessage: false,
      showResult: false,
      importReportModel: {},
      loading: false,
      loader: false,
      longPollingStatus: defaultPollingStatus,
      longPolling: false
    }
  },
  computed: {
    longPollingStatusPercentage() {
      return (this.longPollingStatus.rowsProcessed * 100) / this.longPollingStatus.totalRecords
    }
  },
  methods: {
    closeDialogImport() {
      this.selectedFile = ''
      this.showInlineMessage = false
      this.showResult = false
      this.importReportModel = []
      this.longPolling = false
      this.loading = false
      this.loader = false
      this.longPollingStatus = defaultPollingStatus
      this.$emit('update:dialogImport', false)
      this.$emit('load')
    },
    handleFileImport(event) {
      this.selectedFile = event.target.files[0]
      this.showInlineMessage = false
    },
    async uplod_file() {
      this.loader = true
      this.showInlineMessage = false
      this.longPolling = true
      try {
        let formData = new FormData()
        formData.append('file', this.selectedFile)
        // post document
        const result = await this.axios.post(`${apiURL}/productionForms/${this.productionFormId}/import`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        if (await result) {
          this.longPollingStatus = result.data

          this.showResult = true
          setTimeout(() => this.longPoller(this), 3000)
        }
      } catch (e) {
        if (e?.response?.data?.title === 'DBM_ERROR_164') {
          this.errorMessage = showError(e, 'inline')
        }
        this.showInlineMessage = true
        this.errorMessage = showError(e, 'inline')
        this.loader = false
        this.longPolling = false
      }
    },
    async longPoller(componentReference) {
      try {
        const result = await this.axios.get(`${apiURL}/productionForms/${this.productionFormId}/import`)
        if (await result) {
          this.longPollingStatus = result.data
          console.log('longPoller', this)
          if (this.longPollingStatus.totalRecords == this.longPollingStatus.rowsProcessed) {
            //done ..
            this.longPolling = false
            this.loader = false

            // jetzt logfile json laden ..
            // und darstellen
          } else {
            //poll again
            if (this.longPolling == true) setTimeout(this.longPoller, 3000)
          }
        }
      } catch (e) {
        this.showInlineMessage = true
        this.errorMessage = showError(e, 'inline')
        this.loader = false
        this.longPolling = false
      }
    }
  }
})
</script>
