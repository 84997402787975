<template>
  <v-dialog :retain-focus="false" v-model="dialog" max-width="1400px" id="production-form-validation-dialog">
    <v-card>
      <v-card-title><span v-html="$t('production_form_validations_title')" /></v-card-title>
      <v-card-text>
        <h1 v-if="validations.fiveKg" align-center v-html="$t('production_form_validations_5kg')"></h1>
        <v-data-table
          v-if="validations.fiveKg && validationFields"
          :headers="validationFields"
          :loading="loading"
          :items="validations.fiveKg"
          :fields="validationFields"
          density="compact"
        ></v-data-table>
        <h1 v-if="validations.notPresent" align-center v-html="$t('production_form_validations_not_present')"></h1>
        <v-data-table
          v-if="validations.notPresent && validationFields"
          :headers="validationFields"
          :loading="loading"
          :items="validations.notPresent"
          :fields="validationFields"
          density="compact"
        ></v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn id="check_btn" color="primary" @click="handleFinishReading()">
          <span v-html="$t('production_form_validations_complete')" />
        </v-btn>
        <v-btn id="cancel_btn" color="secondary" @click="dialog = false">
          <span v-html="$t('production_form_validations_cancel')" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL } from '@/main'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productionFormValidationDialog',
  data() {
    return {
      action: <string | null>null,
      productionFormId: <number | null>null,
      loading: false,
      dialog: false,
      validations: { fiveKg: <[] | null>null, notPresent: <[] | null>null },
      validationFields: <DTSHeader[]>[
        {
          title: this.$t('production_form_table_agis_id'),
          key: 'agisId',
          sortable: true,
          align: 'start'
        },
        {
          title: this.$t('production_form_table_number'),
          key: 'zip',
          align: 'start',
          sortable: true
        },
        {
          title: this.$t('production_form_table_name'),
          key: 'locality',
          sortable: true
        },
        {
          title: this.$t('production_form_table_quantity'),
          key: 'quantity',
          sortable: true
        },
        {
          title: this.$t('production_form_table_name1'),
          key: 'name1',
          sortable: true
        },
        {
          title: this.$t('production_form_table_name2'),
          key: 'name2',
          sortable: true
        }
      ]
    }
  },
  emits: ['reload'],
  computed: {},
  methods: {
    async openDialog(id: number, action: string) {
      this.productionFormId = id
      this.action = action
      this.validations.fiveKg = null
      this.validations.notPresent = null
      await this.loadValidations()
    },
    // setimported is only when ProductionFormStatus.CREATED |  ProductionFormStatus.IN_PROGRESS
    // productionFormService setImported
    async loadValidations() {
      if (this.action === 'setImported') {
        await this.validateSetInProgress(this.productionFormId)
        if (this.validations.fiveKg.length || this.validations.notPresent.length) {
          this.dialog = true
        } else {
          await this.handleFinishReading()
        }
      } else {
        await this.handleFinishReading()
      }
    },
    async validateSetInProgress(productionFormId: number) {
      try {
        const result = await this.axios.get(
          apiURL + `/productionForms/${productionFormId}/preValidateInProgress`,
          {},
          { headers: { 'Content-Type': 'application/json' } }
        )
        this.validations.fiveKg = result.data?.fiveKg ? result.data.fiveKg : []
        this.validations.notPresent = result.data?.notPresent ? result.data.notPresent : []
      } catch (e) {
        showError(e)
      }
    },
    async handleFinishReading(productionFormId: number) {
      try {
        await this.axios.get(apiURL + `/productionForms/${this.productionFormId}/${this.action}`, {})
        // await this.load()
        this.$emit('reload', this.productionFormId)

        this.dialog = false
      } catch (e) {
        showError(e)
      }
    }
  }
})
</script>
