<template>
  <v-form ref="productionFormSearch" name="productionFormSearch" model="productionFormSearch" @submit.prevent="search()">
    <v-layout row justify-center>
      <v-overlay v-model="downloadLoading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <div class="container container-xl">
      <h1 v-html="$t('production_form_read_title')"></h1>
      <v-row class="row search spacer-md">
        <v-col xs="12" sm="6" md="2">
          <search-legal-entity ref="search-legal-entity" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2">
          <search-farm ref="search-farm" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2" v-if="$privileges.has({ path: '/dairies', permission: 'search' })">
          <search-dairy ref="search-dairy" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2" v-else-if="$privileges.has({ path: '/dairies', permission: 'searchManaged' })">
          <search-dairy-managed ref="search-dairy-managed" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2">
          <search-contact ref="search-contact" @search="search" />
        </v-col>
        <v-col xs="12" sm="6" md="2">
          <search-production-overview ref="search-production" @search="search" />
        </v-col>
      </v-row>

      <div class=""></div>
      <div class="">
        <v-btn color="primary" @click="search"><span v-html="$t('production_form_search')" /></v-btn>
        <v-btn color="secondary" @click="reset"><span v-html="$t('production_form_reset')" /></v-btn>
      </div>
      <v-progress-linear class="mt-3" v-if="loading" indeterminate color="cyan"></v-progress-linear>
      <div v-if="this.productionForms.length > 0">
        <div v-for="productionForm in productionForms" :key="productionForm.id">
          <v-row>
            <v-col sm="6" md="6" xl="6">
              <p>
                {{ productionForm.dairyIdent }}, {{ productionForm.name1 }} {{ productionForm.name2 }} - <br />
                <v-chip v-for="(statusInfo, i) in productionForm.statusInfo" v-bind:key="i" variant="flat" class="chip-bright ma-2"
                  >{{ statusInfo.count }}
                  {{ statusInfo[this.$getLangKey()] }}
                </v-chip>
                <v-chip v-if="productionForm.countRemarks > 0" variant="flat" class="chip-bright ma-2"
                  >{{ productionForm.countRemarks }} &nbsp;
                  <span v-html="$t('remarks')" />
                </v-chip>
                <v-chip v-if="productionForm.countFiles > 0" variant="flat" class="chip-bright ma-2"
                  >{{ productionForm.countFiles }} &nbsp;
                  <span v-html="$t('attachments')" />
                </v-chip>
                <v-btn size="sm" color="secondary" @click="toggleHistoryDialog(productionForm.id)">
                  <v-icon dbmblueprimary density="compact">mdi-clipboard-text-clock</v-icon>
                </v-btn>
              </p>
            </v-col>
            <v-col sm="2" md="4" xl="4">
              <v-btn
                color="primary"
                class="shadow-none"
                :to="{
                  name: 'processingForms_edit',
                  params: { id: productionForm.processingFormId }
                }"
                ><span v-html="$t('production_form_direct_to_tsm')"
              /></v-btn>
            </v-col>
            <v-col sm="2" md="4" xl="4" :key="index" v-for="(buttonAction, index) in buttonActions(productionForm.statusInfo)">
              <v-btn :disabled="productionForm.dairyInactive" color="primary" @click="handleStatusButtonClicked(productionForm, buttonAction.action)">
                {{ buttonAction.label }}
              </v-btn>
            </v-col>
            <v-col sm="2">
              <v-menu id="importexportmenu" lass="m-2" offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn id="processingform_importexport_btn" color="secondary" v-bind="props">
                    <span v-html="$t('processingform_import_export_btn')" />
                    <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item id="show_import" key="import" @click="openImportDialog(productionForm.id)">
                    <v-list-item-title v-html="$t('production_form_import')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item id="show_export_data" key="exportData" @click="exportData(productionForm.id)">
                    <v-list-item-title v-html="$t('production_form_exportdata')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item id="show_export_default" key="exportDefault" href="/files/public/templates/mpd1_template.xlsx">
                    <v-list-item-title v-html="$t('production_form_exportdefault')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item id="show_refresh" key="refresh" @click="refresh(productionForm) && refreshCountRemarksAndFiles(productionForm)">
                    <v-list-item-title v-html="$t('production_form_refresh')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item id="show_print" key="print" @click="print(productionForm.id)" :disabled="loadingReportMPD1">
                    <v-list-item-title>
                      <v-progress-linear class="mt-3" v-if="loadingReportMPD1" indeterminate color="cyan"></v-progress-linear>
                      <span v-html="$t('print')"></span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <productionFormEdit
            :productionFormsArrayLength="productionForms.length"
            :productionForm="productionForm"
            :periodeMonth="query && query.periodeMonth ? parseInt(query.periodeMonth) : 0"
            :loadPreviousMonths="query.loadPreviousMonths"
            :term="term"
            :animalType="SearchProductionOverviewStore.animalTypeId"
            :productionFormFindQuery="query"
            @reload-production-form="updateProductionFormWithPatchResult"
          />
        </div>

        <production-form-import-dialog v-model:dialogImport="dialogImport" :productionFormId="dialogProductionFormId" @load="load" />

        <v-dialog
          max-width="1200px"
          v-if="
            (selectedProductionFormId && $privileges.has({ path: '/histories', permission: 'read' })) ||
            $privileges.has({ path: '/remarks', permission: 'read' }) ||
            $privileges.has({ path: '/documents/uploads', permission: 'read' })
          "
          v-model="historyDialog"
          persistent
        >
          <v-card>
            <v-card-title>
              <span class="text-h5" v-html="$t('production_forms_documents_history_dialog_title')"></span>
            </v-card-title>

            <v-card-text>
              <rdhComponent
                :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
                :remarks_show="true"
                :remarks_tag="'production_forms_' + selectedProductionFormId"
                :documents_show="true"
                :documents_tag="'production_forms_' + selectedProductionFormId"
                :history_show="true"
                :history_url="'/productionForms/' + selectedProductionFormId"
                ref="rdh"
              ></rdhComponent>
            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn size="sm" @click="toggleHistoryDialog(selectedProductionFormId)">
                <span v-html="$t('product_groups_button_cancel')" />
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="text-center">
          <v-pagination v-if="totalPages > 1" v-model="currentPage" :length="totalPages" @update:modelValue="handlePageChange"></v-pagination>
        </div>
      </div>
      <div v-else>
        <v-col>
          <div class="mt-4" v-html="$t('production_form_read_no_content')"></div>
        </v-col>
      </div>
    </div>
    <productionFormValidationDialog ref="productionFormValidationDialog" @reload="load" />
  </v-form>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import moment from 'moment'
import productionFormEdit from './edit.vue'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchDairyManaged from '@/components/searchCards/searchDairyManaged.vue'
import { useSearchDairyManagedStore } from '@/store/SearchDairyManagedStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import { fileCreator, showError } from '@/services/axios'
import ProductionFormImportDialog from './productionFormImportDialog.vue'
import rdhComponent from '@/components/rdh/rdhComponent'
import searchProductionOverview from '@/components/searchCards/searchProductionOverview.vue'
import productionFormValidationDialog from './productionFormValidationDialog.vue'
import { useSearchProductionOverviewStore } from '@/store/SearchProductionOverviewStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productionForms',
  data() {
    return {
      loadingReportMPD1: false,
      period: undefined,
      currentPage: 0,
      totalPages: undefined,
      dialog: false,
      dialogImport: false,
      selectedProductionFormId: undefined,
      historyDialog: false,
      expanded: false,
      menu1: false,
      menu2: false,
      menu3: false,
      validations: [],
      totalElements: 0,
      loading: false,
      downloadLoading: false,
      productionForms: [],
      statuses: [],
      dialogProductionFormId: 0,
      productionFormSearch: null
    }
  },
  components: {
    productionFormEdit,
    searchLegalEntity,
    searchFarm,
    searchDairy,
    searchDairyManaged,
    searchProductionOverview,
    searchContact,
    ProductionFormImportDialog,
    rdhComponent: rdhComponent,
    productionFormValidationDialog
  },
  computed: {
    SearchProductionOverviewStore() {
      useSearchProductionOverviewStore()
    },
    params() {
      return {
        status: this.SearchProductionOverviewStore.status,
        animalTypeId: this.SearchProductionOverviewStore.animalTypeId,
        period: this.SearchProductionOverviewStore.period ? this.SearchProductionOverviewStore.period : null,
        loadPreviousMonths: this.SearchProductionOverviewStore.loadPreviousMonths,
        quantityFrom: this.SearchProductionOverviewStore.quantityFrom,
        quantityTo: this.SearchProductionOverviewStore.quantityTo,
        createdFrom: this.SearchProductionOverviewStore.createdFrom,
        createdUntil: this.SearchProductionOverviewStore.createdUntil,
        countRemarks: this.SearchProductionOverviewStore.countRemarks,
        countFiles: this.SearchProductionOverviewStore.countFiles
      }
    },
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchDairyManagedStore(), useSearchDairyManagedStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items)
      ]
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions()
      }
    }
  },
  methods: {
    toggleHistoryDialog(id) {
      this.historyDialog = !this.historyDialog
      this.selectedProductionFormId = id
    },
    handlePageChange(value) {
      this.currentPage = value
      this.routerPushPage(value)
      //this.search()
    },
    closeDialog() {
      this.dialog = false
    },
    async refresh(productionForm) {
      if (!this.$privileges.has({ path: '/productionFormRows', permission: 'write' })) {
        return
      }
      try {
        const response = await this.axios.patch(apiURL + '/productionForms/' + productionForm.id + '/updateRows')
        // response can be empty (http-204), i.e. direct marketer
        if (response.data) {
          productionForm.totalUniqueRowCount = response.data.totalUniqueRowCount
          productionForm.statusInfo = response.data.statusInfo
        }
      } catch (e) {
        showError(e)
      } finally {
      }
    },
    async refreshCountRemarksAndFiles(productionForm) {
      try {
        this.downloadLoading = true
        const response = await this.axios.get(apiURL + '/productionForms/' + productionForm.id + '/findByTagCountRemarksAndFiles', {
          params: {
            tag: 'production_form'
          }
        })
        if (await response) {
          // response can be empty (http-204), i.e. direct marketer
          if (response.data) {
            productionForm.countRemarks = response.data.remarkCount
            productionForm.countFiles = response.data.fileCount
          }
        }
      } catch (e) {
        showError(e)
      } finally {
        this.downloadLoading = false
      }
    },
    async print(id) {
      try {
        this.loadingReportMPD1 = true
        const result = await this.axios.get(`/api/productionForms/${id}/reportMPD1`, {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob',
          params: { processingFormId: this.$route.params.id }
        })
        fileCreator(await result)
      } catch (e) {
        //TODO: possibly find a better solution for handling these blob errors
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingReportMPD1 = false
      }
    },
    async search() {
      if (!(await this.$refs.productionFormSearch.validate()).valid) {
        return
      }
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch(() => {
          // route duplicated
          this.load()
        })
    },
    updateProductionFormWithPatchResult(productionFormPatchResult) {
      var foundIndex = this.productionForms.findIndex((x) => x.id == productionFormPatchResult.productionFormId)
      this.productionForms[foundIndex].statusInfo = productionFormPatchResult.statusInfo
    },
    reset() {
      this.$refs.productionFormSearch.reset()
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-farm'].reset()
      if (this.$refs['search-dairy']) this.$refs['search-dairy'].reset()
      if (this.$refs['search-dairy-managed']) this.$refs['search-dairy-managed'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-production'].reset()
      this.query = this.defaultQuery
      this.SearchProductionOverviewStore.loadPreviousMonths = 'PREVIOUS'
    },
    async exportData(productionFormId) {
      try {
        this.downloadLoading = true
        const response = await this.axios.get(apiURL + '/productionForms/' + productionFormId + '/export', {
          params: {},
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'productionForms' + productionFormId + '.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloadLoading = false
      }
    },
    openImportDialog(id) {
      this.dialogImport = true
      this.dialogProductionFormId = id
    },
    async load() {
      if (!(await this.$refs.productionFormSearch.validate()).valid) {
        return
      }
      this.loading = true

      try {
        this.productionForms = []
        const response = await this.axios.get(apiURL + '/productionForms/find', {
          params: this.query
        })
        const productionForms = await response.data.content

        this.currentPage = response.data.number + 1
        this.totalPages = response.data.totalPages

        this.productionForms = Array.isArray(productionForms) ? productionForms : []
        this.totalElements = this.checkPage(response.data.totalElements)
        for (const form of this.productionForms) {
          if (form.dairyValidUntil === null) {
            form.dairyInactive = false
          } else {
            // GR-220605-10
            let minusThirteenMonth = new moment().add(-13, 'months').startOf('month').toDate().getTime()
            let dairyValidUntil = Date.parse(form.dairyValidUntil)
            let formValidFrom = Date.parse(form.validFrom)
            form.dairyInactive = formValidFrom < minusThirteenMonth || formValidFrom > dairyValidUntil
          }
          form.loading = true
          this.refresh(form)
          this.refreshCountRemarksAndFiles(form)
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async handleStatusButtonClicked(productionForm, guiButtonAction) {
      this.$refs.productionFormValidationDialog.openDialog(productionForm.id, guiButtonAction)
    },

    buttonActions(statusInfo) {
      let buttonActions = []
      let buttonAction = {}
      for (const status of statusInfo) {
        if (status.guiButtonAction && buttonActions.findIndex((x) => x.action === status.guiButtonAction) === -1) {
          buttonAction = { action: status.guiButtonAction, label: '?' }
          if (status.guiButtonAction === 'setImported') {
            buttonAction.label = this.$t('production_form_read_finish_reading_button_imported')
          } else if (status.guiButtonAction === 'setInProgress') {
            buttonAction.label = this.$t('production_form_read_finish_reading_button_in_progress')
          } else if (status.guiButtonAction === 'setBackInCorrection') {
            buttonAction.label = this.$t('production_form_read_finish_reading_button_correction')
          } else if (status.guiButtonAction === 'setValidation') {
            buttonAction.label = this.$t('production_form_read_finish_reading_button_validated')
          }
          buttonActions.push(buttonAction)
        }
      }
      return buttonActions
    }
  },
  mounted() {
    if (!this.SearchProductionOverviewStore.loadPreviousMonths) {
      this.SearchProductionOverviewStore.loadPreviousMonths = 'PREVIOUS'
    }
    if (this.$route.query.size) this.load()
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  }
})
</script>
<style scoped>
#input-usage .v-text-field__prepend-outer,
#input-usage .v-text-field__append-outer,
#input-usage .v-text-field__slot,
#input-usage .v-messages {
  border: 1px dashed rgba(0, 0, 0, 0.4);
}

.align-right {
  text-align: right;
}

.file-import {
  margin-top: 20px;
}

.wrong-file-type-alert {
  margin-top: 5px;
}

.report-box {
  margin-top: 20px;
}

/*// todo remove*/
.v-input__slot > .v-text-field__slot {
  background: red;
  /*padding: 12px 1px 0 1px;*/
  /*transition: all 0.3s ease;*/
  /*margin-bottom: 0;*/
}

.v-input--is-readonly .v-input__slot > .v-text-field__slot,
.v-input--is-disabled .v-input__slot > .v-text-field__slot,
.v-input--is-disabled .v-select__slot {
  background: green !important;
}
</style>
